import { ActionResponse } from 'types/DataResult';

export interface GetLogsFileResponse extends ActionResponse {
  status: string;
  fileName: string;
}

export enum Command {
  GET_LOG = 'GetLog',
  GET_DIAGNOSTICS = 'GetDiagnostics',
  INSTALL_CERTIFICATE = 'InstallCertificate',
}
